import React from 'react';

function Scoreboard(props) {

    return (
        <div className="App">
        <header className="App-header">
            <h2>Scoreboard</h2>
        </header>
        <div>
            <table>
            <tbody>
            <tr>
                <th>Place</th>
                <th>Username</th>
                <th>Score</th>
            </tr>
            
            {props.scores && props.scores.map((player, index) => {
                return (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{player[0]}</td>
                    <td>{player[1]}</td>
                </tr>
                );
            })}
            </tbody>
            </table>
        </div>
        </div>
    );
}

export default Scoreboard;