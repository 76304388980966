import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function Questions(props) {
    const [answer, setAnswer] = useState('')
    
    const sendAnswer = () => {
        props.send(answer)
    }

    const sendAnswerButton = (number) => {
        props.send(number)
    }

    return (
    <div className="App">
        <h1>Trivia {props.id}</h1>
        <Box>
        <header className="App-header">
            <Box>
                <h2>Question {props.question.question_id}</h2>
            </Box>
            <Box><h4>Time left: {props.timer}</h4></Box>
        </header>
        <Box>
            <h3>{props.question.question_title}</h3>

            {(props.question.question_type === 'button') && (
                <Box>
                <Button variant="outlined" onClick={() => sendAnswerButton('1')}>{props.question.question_options['1']}</Button>
                <Button variant="outlined" onClick={() => sendAnswerButton('2')}>{props.question.question_options['2']}</Button>
                <Button variant="outlined" onClick={() => sendAnswerButton('3')}>{props.question.question_options['3']}</Button>
                <Button variant="outlined" onClick={() => sendAnswerButton('4')}>{props.question.question_options['4']}</Button>
                </Box>
            )}
            {(props.question.question_type === 'chat') && (
                <div>
                <Box> 
                    <ol style={{listStyleType: "none"}} >{props.chat.map((player, index) => {
                        return (
                        <li key={index}>
                            {player.username}: {player.message}
                        </li>
                        );
                    })}</ol>
                </Box>
                <TextField id="filled-basic" label="Answer" variant="filled" value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                <Button variant="contained" onClick={() => sendAnswer()}>Send</Button>
                </div>
            )}
            {(props.question.question_type === 'text') && (
                <div>
                <TextField id="filled-basic" label="Answer" variant="filled" value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                <Button variant="contained" onClick={() => sendAnswer()}>Send</Button> 
                </div>
            )}
            {(props.result === true) && (
                <Alert severity="success" onClose={() => props.setResult('')} >
                    <AlertTitle>Correct!</AlertTitle>
                </Alert>
            )}
            {(props.result === false) && (
                <Alert severity="error" onClose={() => props.setResult('')} >
                    <AlertTitle>Incorrect!</AlertTitle>
                </Alert>
            )}
        </Box>
        </Box>

    </div>
  );
}

export default Questions;