import React from 'react';
import { useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function Landing(props) {
  const [username, setUsername] = useState('');
  const [n_alumno, setN_alumno] = useState('');

  const startGame = () => {
    if (n_alumno === ''){
      alert('Ingresa tu número de alumno')
      return
    } else {
      props.start(username, n_alumno)
    }
  }

  return (
    <div className='landing-container'>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className='landing-box'
      >
        <TextField id="filled-basic" label="N° Alumno" variant="filled" value={n_alumno} onChange={(e) => setN_alumno(e.target.value)}/>
        <TextField id="filled-basic" label="Username" variant="filled" value={username} onChange={(e) => setUsername(e.target.value)}/>
        <Button variant="contained" onClick={() => startGame()}>Play</Button> 
      
    </Box>
  </div>
  );
}

export default Landing;