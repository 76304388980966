import React from 'react';

function Lobby(props) {

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Lobby
        </h1>
      </header>
      <p>
        {props.message}
      </p>
      <ol>{props.players.map((player, index) => {
        return (
          <li key={index}>
            {player}
          </li>
        );
      })}</ol>
    </div>
  );
}

export default Lobby;