import React from 'react';

function End(props) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>
            Trivia {props.id} winners
          </h1>
        </header>
        <table>
          <tbody>
          <tr>
            <th>Place</th>
            <th>Username</th>
            <th>Score</th>
            <th>Streak</th>
          </tr>
          
          {props.winners && props.winners.map((player, index) => {
            return (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{player.username}</td>
                <td>{player.score}</td>
                <td>{player.streak}</td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    );
  }
  
  export default End;