import React from 'react';

function Streak(props) {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            {props.user} is on a {props.streak} questions streak!
          </p>
        </header>
        
      </div>
    );
  }
  
  export default Streak;