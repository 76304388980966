import Landing from './trivia/landing';
import Lobby from './trivia/lobby';
import End from './trivia/end';
import { useState, useEffect} from 'react';
import Scoreboard from './trivia/scoreboard';
import Questions from './trivia/questions';
import Box from '@mui/material/Box';
import Streak from './trivia/streak';

const URL = 'wss://trivia.tallerdeintegracion.cl/connect';

function App() {
  const [landing, setLanding] = useState(true);
  const [lobby, setLobby] = useState(false);
  const [game, setGame] = useState(false);
  const [end, setEnd] = useState(false);
  const [id, setId] = useState('');
  const [message, setMessage] = useState('');
  const [players, setPlayers] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [winners, setWinners] = useState([]);
  const [question, setQuestion] = useState('');
  const [scores, setScores] = useState([]);
  const [user_streak, setUser_streak] = useState('');
  const [streak, setStreak] = useState(0);
  const [timer, setTimer] = useState(0);
  const [chat, setChat] = useState([]);
  const [result, setResult] = useState('');
  const [websocket, setWebsocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(URL);

    ws.onopen = (event) => {
      console.log('Connected')
    };

    ws.onmessage = (event) => {
      const res = JSON.parse(event.data);

      if (res.type === 'accepted') {
        setId(res.trivia_id);
        setLanding(false);
        setEnd(false)
        setChat([])
        setStreak('')
        console.log('aceptado')
        setLobby(true)
      } else if (res.type === 'denied') {
        alert(res.reason);
        console.log('rechazado', res.reason)
      } else if (res.type === 'lobby') {
        setLobby(true)
        setEnd(false)
        setStreak('')
        setSeconds(res.seconds_remaining);
        setMessage(res.message);
        setPlayers(res.players);
        console.log('lobby')
      } else if (res.type === 'question') {
        setLobby(false);
        setEnd(false)
        setGame(true);
        setResult('')
        setQuestion(res)
        console.log(res)
      } else if (res.type === 'score') {
        setLobby(false);
        setEnd(false)
        setGame(true);
        sortScores(res.scores)
        console.log('scoreboard')
      } else if (res.type === 'streak') {
        setLobby(false);
        setEnd(false)
        setGame(true);
        setStreak(res.streak)
        setUser_streak(res.username)
        console.log('streak')
      } else if (res.type === 'timer') {
        setLobby(false);
        setEnd(false)
        setGame(true);
        setTimer(res.seconds_remaining);
        console.log('timer')
      } else if (res.type === 'result') {
        setLobby(false);
        setEnd(false)
        setGame(true);
        setResult(res.correct)
        console.log('result')
      } else if (res.type === 'chat') {
        setLobby(false);
        setEnd(false)
        setGame(true);
        setResult('')
        setChat(chat => [...chat, {'username': res.username, 'message': res.message}])
        console.log('chat')
      } else if (res.type === 'highscore') {
        setLobby(false);
        setGame(false);
        setEnd(true)
        setResult('')
        setChat([])
        setWinners(res.winners);
        console.log('highscores')
      } else if (res.type === 'disconnected') {
        alert(res.message)
      }
    };

    ws.onclose = (event) => {
      console.log('cerrando')
    };

    setWebsocket(ws)

    return () => {
      ws.close();
    };
  }, []);

  const startGame = (username, n_alumno) => {
    if (websocket) {
      websocket.send(JSON.stringify({
        type: "join",
        id: n_alumno,
        username: username
      }));
      console.log('Solicitud enviada');
    }
  }

  const sendAnswer = (answer) => {
    if (websocket) {
      websocket.send(JSON.stringify({
        type: "answer",
        question_id: question.question_id,
        value: answer
      }));
      console.log('Respuesta enviada');
    }
  }

  const sortScores = (scores) => {
    const matrizDePares = Object.entries(scores);
    matrizDePares.sort((a, b) => b[1] - a[1]);
    const listaOrdenada = matrizDePares.map(([clave, valor]) => [clave, valor]);
    setScores(listaOrdenada)
  }

  return (
    <div>
      {(landing === true) && (
        <div>
          <Landing start={startGame}/>
        </div>
      )}
      {(lobby === true) && (
        <div>
          <Lobby seconds={seconds} players={players} message={message} id={id}/>
        </div>
      )}
      {(game === true) && (
        <div>
          <div style={{display: "flex"}}>
          <Box sx={{boxShadow: 1,
              borderRadius: 2,
              p: 2,
              maxWidth: 800, 
              maxHeight: 800}}>

            <Questions timer={timer} question={question} send={sendAnswer} chat={chat} result={result} id={id} setResult={setResult}/>
          </Box>
          
          <Box sx={{boxShadow: 1,
              borderRadius: 2,
              p: 2,
              maxWidth: 400, 
              maxHeight: 580,  
              backgroundColor: '#85C1E9' }}>

            <Scoreboard scores={scores} id={id}/>
          </Box>
          </div>
          {(streak) && (
            <Box sx={{ boxShadow: 1,
              borderRadius: 2,
              p: 2,
              maxWidth: 400,
              backgroundColor: '#5DADE2' }}>

              <Streak streak={streak} user={user_streak} id={id}/>
            </Box>
          )}
        </div>
      )}
      {((game === false) && (landing === false) && (lobby === false) && (end === true)) && (
        <div>
        <End winners={winners} id={id}/>
        </div>
      )}
    </div>
  );
}

export default App;
